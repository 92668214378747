<div *nzModalTitle>
  <i nz-icon nzType="warning" style="color: red; font-size: 18px" nzTheme="outline"></i>
  <span style="margin-left: 7px">Внутренняя ошибка сервера</span>
</div>
<nz-descriptions [nzColon]="true" nzLayout="horizontal" [nzColumn]="1" nzBordered>
  <nz-descriptions-item nzTitle="Дата/время">{{ now | date: 'dd.MM.yyyy HH:mm:ss' }}</nz-descriptions-item>
  <nz-descriptions-item nzTitle="ID запроса">{{ errorObj.requestId }}</nz-descriptions-item>
  <nz-descriptions-item nzTitle="Ошибка">{{ errorObj.message }}</nz-descriptions-item>
</nz-descriptions>
<div *nzModalFooter></div>
