import { ErrorHandler, inject, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BadRequest } from '@core/http/models/bad-request';
import { Forbidden } from '@core/http/models/forbidden';
import { Unauthorized } from '@core/http/models/unauthorized';
import { MissingClaimError } from '@core/session/models/missing-claim-error';
import { InternalServerError } from '@core/http/models/internal-server-error';
import { Conflict } from '@core/http/models/conflict';
import { NzMessageService } from 'ng-zorro-antd/message';
import { InternalServerErrorModalComponent } from '@core/http/components/internal-server-error-modal/internal-server-error-modal.component';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { config } from 'rxjs';
import { CancelledResult } from '@shared/result';
import { ForbiddenClaimError } from '@core/session/forbidden-claim-error';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
  private notificationService = inject(NzNotificationService);
  private readonly chunkFailedMessage = /ChunkLoadError/;
  private readonly afterCheckedErrorMessage = /Error: NG0100/;
  reloadModalTriggered: boolean = false;

  constructor(
    private nzModalService: NzModalService,
    private nzMessageService: NzMessageService,
  ) {
    config.onUnhandledError = (err) => {
      this.handleError(err);
    };
  }

  handleError(error: any): void {
    console.error('ERROR', error);

    if (error instanceof CancelledResult) {
      this.nzMessageService.warning('Действие отменено');
      return;
    }

    if (error instanceof ForbiddenClaimError) {
      this.nzMessageService.warning(error.message);
      return;
    }

    if (this.chunkFailedMessage.test(error.message)) {
      this.handleAvailableUpdates();
      return;
    }

    if (this.afterCheckedErrorMessage.test(error)) {
      this.nzMessageService.warning(error, {
        nzPauseOnHover: true,
      });
      return;
    }

    if (error instanceof HttpErrorResponse && error.status === 400) {
      this.nzMessageService.warning(error.error.message);
      return;
    }

    if (error instanceof BadRequest) {
      this.nzMessageService.warning(error.error.message);
      return;
    }

    if (error instanceof InternalServerError) {
      console.log(error, 'error');
      this.nzModalService.create({
        nzContent: InternalServerErrorModalComponent,
        nzData: error,
      });
    }

    if (error instanceof Unauthorized) {
      console.log(error, 'error');
      // this.sessionService.logout()

      return;
    }

    if (
      error instanceof BadRequest ||
      error instanceof Forbidden ||
      error instanceof Unauthorized ||
      error instanceof MissingClaimError ||
      error instanceof InternalServerError ||
      error instanceof Conflict
    ) {
      return;
    }
    if (
      error.toString().includes('"type":"InternalServerError"') ||
      error.toString().includes('"type":"BadRequest"') ||
      error.toString().includes('"type":"Forbidden"') ||
      error.toString().includes('"type":"Conflict"') ||
      error.toString().includes('"type":"LicenceException"') ||
      error.toString().includes('Unauthorized') ||
      error.toString().includes('"type":"MissingClaimError"')
    ) {
      return;
    }
    this.notificationService.error('Ошибка', 'Произошла непредвиденная ошибка');
  }

  handleAvailableUpdates() {
    if (this.reloadModalTriggered) {
      return;
    }

    this.reloadModalTriggered = true;
    this.nzModalService.info({
      nzTitle: 'Обновите страницу',
      nzContent: 'Для продолжения работы требуется обновление страницы браузера',
      nzCancelText: null,
      nzMaskClosable: false,
      nzClosable: false,
      nzOnOk: () => window.location.reload(),
    });
  }
}
