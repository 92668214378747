import { Component, inject } from '@angular/core';
import { InternalServerError } from '@core/http/models/internal-server-error';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { antdModules } from '@shared/ant-modules';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'rais-internal-server-error-modal',
  templateUrl: './internal-server-error-modal.component.html',
  styleUrls: ['./internal-server-error-modal.component.less'],
  imports: [
    antdModules,
    DatePipe
  ],
  standalone: true
})
export class InternalServerErrorModalComponent {
  errorObj: InternalServerError = inject<InternalServerError>(NZ_MODAL_DATA);
  now = new Date();

  constructor(private nzModalRef: NzModalRef) {
    console.log('InternalServerErrorModalComponent ctor');
    // this.nzModalRef.getConfig().nzTitle = 'Внутренняя ошибка сервера';
  }
}
