import { throwError } from 'rxjs';

export class ForbiddenClaimError extends Error {
  constructor(public message: string = 'У вас нет прав на выполнение этого действия') {
    super();
  }

  static throw(message: string = 'У вас нет прав на выполнение этого действия') {
    return throwError(() => new ForbiddenClaimError(message));
  }
}
